



































































































import {
  computed,
  defineComponent,
  inject,
  PropType,
} from "@nuxtjs/composition-api";
import { SfColor, SfHeading, SfImage } from "@storefront-ui/vue";
import type { Aggregation } from "~/modules/GraphQL/types";

export default defineComponent({
  components: {
    SfColor,
    SfHeading,
    SfImage,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
  },
  setup() {
    const { isFilterSelected } = inject("UseFiltersProvider");
    const selected = computed(
      () => (id: string, optVal: string) => isFilterSelected(id, optVal)
    );
    const swatchImg = (imageUrl) => {
      const baseMediaUrl = process.env.VsfImageProviderBaseUrl;
      return baseMediaUrl ? `${baseMediaUrl}/attribute/swatch/${imageUrl}` : "";
    };
    return {
      selected,
      swatchImg,
    };
  },
});
